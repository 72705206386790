import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

export const mainNavigationPaths = ["", "about", "projects", "blog", "random"];

export const pageQuery = graphql`
  query LandingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "landing-page" } }) {
      frontmatter {
        title
        heading
        subheading
        description
      }
    }
  }
`;
interface LandingPageTemplateProps {
  image: any;
  title: string;
  heading: string;
  subheading: string;
  description: string;
}

export const LandingPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  description
}: LandingPageTemplateProps) => {
  return (
    <>
      <p>{title}</p>
    </>
  );
};

const LandingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout disableNavbar={true}>
      <LandingPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
      />
    </Layout>
  );
};

export default LandingPage;
