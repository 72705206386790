import * as React from "react";
import ThemeContext from "../context/ThemeContext";

interface ButtonProps {
  children?: boolean | string | React.ReactNode;
  element?: "button" | "a";
}

const Button = ({
  element = "button",
  children,
  ...otherProps
}: ButtonProps) => {
  const { theme, toggleTheme } = React.useContext(ThemeContext);

  const DynamicElement = element;
  return (
    <DynamicElement
      className={`button ${theme === "dark" ? "is-light" : "is-black"}`}
      {...otherProps}
    >
      {children}
    </DynamicElement>
  );
};

export default Button;
