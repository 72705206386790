import * as React from "react";
import Typist, { TypistProps } from "react-typist";
import { navigate } from "gatsby";

import useKeyPress from "../hooks/useKeyPress";
import { mainNavigationPaths } from "../templates/landing-page";

function validateInputOnEnter(rawInput: string) {
  let result = {
    valid: false,
    navigate: false,
    path: "",
    message: "No valid cmd!"
  };
  const parts = rawInput.split(" ") || [];
  const cmd = parts[0];
  const path = parts[1];
  switch (cmd) {
    case "cd":
      const parsedPath = path.length && path.replace(/(^.\/|^\/)/, "");
      if (mainNavigationPaths.includes(parsedPath)) {
        result = {
          valid: true,
          navigate: true,
          path: `/${parsedPath}`,
          message: ""
        };
      } else {
        result.message = `cd: no such file or directory: ${parsedPath}`;
      }
      break;
  }
  return result;
}

interface IValidationResult {
  valid: boolean;
  navigate?: boolean;
  path?: string;
  message?: string;
}

function validateInput(input: string, key: string): IValidationResult {
  let answer: IValidationResult;
  switch (key) {
    case "Enter":
      answer = validateInputOnEnter(input);
      break;
    default:
  }
  return answer;
}

interface ConsoleReturnLineProps {
  children: React.ReactNode;
  initialDelay?: number;
  delayAfterRender?: number;
  onRender: () => void;
}

const ConsoleReturnLine = ({
  children,
  initialDelay = 0,
  onRender,
  delayAfterRender = 0
}: ConsoleReturnLineProps) => {
  const [shouldRender, setShouldRender] = React.useState(false);

  // Delay on initial render
  React.useEffect(() => {
    const delayTimer = setTimeout(() => {
      setShouldRender(true);
    }, initialDelay);
    return () => clearTimeout(delayTimer);
  }, [initialDelay]);

  // After render delay
  React.useEffect(() => {
    const delayAfterRenderTimer = setTimeout(() => {
      onRender();
    }, delayAfterRender);
    return () => clearTimeout(delayAfterRenderTimer);
  }, [delayAfterRender]);
  return shouldRender ? children : null;
};

interface ConsoleLineProps {
  user?: string;
  host?: string;
  path?: string;
  showCursor?: boolean;
  children?: React.ReactNode;
  keyboardInput?: string;
  onKeyboardChange?: (value: string) => void;
}

export const ConsoleLine = ({
  user = "dafrie",
  host = "localhost",
  path = "~",
  showCursor = false,
  children = null,
  keyboardInput = "",
  onKeyboardChange
}: ConsoleLineProps) => {
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  const cursor = showCursor ? (
    <span className={"cursor"}>
      <input
        className="hidden-input"
        value={keyboardInput}
        ref={inputRef}
        spellCheck={false}
        onChange={event => onKeyboardChange(event.currentTarget.value)}
      ></input>
    </span>
  ) : null;
  return (
    <div>
      <span
        className="has-text-weight-bold is-size-7-mobile"
        style={{ marginRight: "2px" }}
      >
        >
      </span>
      <span className="has-text-weight-bold is-size-7-mobile">
        {user}@{host} {path} :{" "}
      </span>
      {children}
      {showCursor ? keyboardInput : null}
      {cursor}
    </div>
  );
};

export interface ConsoleInputStep extends TypistProps {
  content: React.ReactNode;
  printInstantly?: boolean;
  isLocal?: boolean;
}

interface ConsoleInputProps {
  user?: string;
  host?: string;
  steps: ConsoleInputStep[];
  onStepCompleted?: () => void;
  onValidationResult?: (results: ConsoleInputStep[]) => void;
}
export const ConsoleInput = ({
  user = "guest",
  host = "localhost",
  steps,
  onValidationResult
}: ConsoleInputProps) => {
  const [activeLine, setActiveLine] = React.useState(0);
  const [activeLine2, setActiveLine2] = React.useState(0);
  const [keyboardInput, setKeyboardInput] = React.useState("");
  const keysPressed = useKeyPress();

  // const validationResults = [];

  if (keysPressed && keysPressed.size) {
    const answers: IValidationResult[] = [];
    keysPressed.forEach(k => {
      const answer = validateInput(keyboardInput, k);
      answer && answers.push(answer);
    });
    answers.forEach(a => {
      if (a && a.valid && a.navigate) {
        navigate(a.path);
      } else if (a && !a.valid && a.message) {
        onValidationResult([
          {
            printInstantly: true,
            content: <span className="is-size-7-mobile">{a.message}</span>,
            startDelay: 0,
            avgTypingDelay: 0,
            stdTypingDelay: 0
          },
          {
            content: <span className="is-size-7-mobile"></span>,
            startDelay: 0,
            avgTypingDelay: 0
          }
        ]);
      }
    });
  }
  const allSteps = steps || [];

  function onLineFinished(idx: number) {
    setActiveLine(idx === allSteps.length - 1 ? idx : idx + 1);
    if (idx === steps.length - 1) {
    } else {
      setActiveLine(idx + 1);
    }
  }

  function handleInputChange(input: string) {
    setKeyboardInput(input);
  }

  return (
    <>
      {allSteps.map((l, idx) => {
        if (idx > activeLine) {
          return null;
        }
        if (l.printInstantly)
          return (
            <ConsoleReturnLine
              key={`c${idx}`}
              initialDelay={l.startDelay || 0}
              delayAfterRender={l.avgTypingDelay || 0}
              onRender={() => onLineFinished(idx)}
            >
              {l.content}
            </ConsoleReturnLine>
          );

        return (
          <ConsoleLine
            key={`b${idx}`}
            showCursor={idx === activeLine}
            user={l.isLocal ? "guest" : user}
            host={l.isLocal ? "localhost" : host}
            keyboardInput={keyboardInput}
            onKeyboardChange={handleInputChange}
          >
            <Typist
              className="typist"
              cursor={{ show: false }}
              startDelay={l.startDelay}
              avgTypingDelay={l.avgTypingDelay}
              stdTypingDelay={l.stdTypingDelay}
              onTypingDone={() => onLineFinished(idx)}
            >
              {l.content}
            </Typist>
          </ConsoleLine>
        );
      })}
    </>
  );
};
