import React, { useState } from "react";
import { Link } from "gatsby";

import { ConsoleInput, ConsoleInputStep } from "../components/ConsoleInput";
import Button from "../components/Button";

export const mainNavigationPaths = ["", "about", "projects", "blog", "random"];

function getWelcomeMessage(ipAddress: string, joke: string) {
  return (
    <span
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        padding: "1em"
      }}
    >
      Welcome to dafrie's personal website's shell! Have fun exploring!
      <br />
      <br />
      Here is a random, not funny, dad joke for you:
      <br />
      {joke}
      <br />
      <br />
      Last login: {new Date().toLocaleDateString()} from {ipAddress}
    </span>
  );
}

const links = (
  <span
    style={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      padding: "1em"
    }}
  >
    <Button element="a" href="https://github.com/dafrie">
      <span className="icon">
        <i className="fab fa-github"></i>
      </span>
      <span>GitHub</span>
    </Button>
    {/*
    {mainNavigationPaths.map(p => (
      <Link
      
        key={p}
        to={`/${p}`}
        style={{ marginRight: "3em" }}
        activeStyle={{ color: "red" }}
        activeClassName="active"
      >
        <Button>{`/${p}`}</Button>
      </Link>
    ))}
    */}
  </span>
);

function getMenuSteps(ipAddress: string, joke: string, isInitial: boolean) {
  if (isInitial) {
    return [
      {
        content: <span className="is-size-7-mobile">ssh dafrie@localhost</span>,
        startDelay: 100,
        avgTypingDelay: 70,
        isLocal: true
      },
      {
        printInstantly: true,
        content: getWelcomeMessage(ipAddress, joke),
        startDelay: 0,
        avgTypingDelay: 0,
        stdTypingDelay: 0
      },
      {
        content: <span className="is-size-7-mobile">ls</span>,
        startDelay: 500,
        avgTypingDelay: 200
      },
      {
        printInstantly: true,
        content: (
          <span
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              padding: "1em"
            }}
          >
            Sorry, not much to do here yet!
          </span>
        ),
        startDelay: 0,
        avgTypingDelay: 0
      },
      {
        printInstantly: true,
        content: links,
        startDelay: 0,
        avgTypingDelay: 0,
        stdTypingDelay: 0
      },
      {
        content: <span className="is-size-7-mobile"></span>,
        startDelay: 0,
        avgTypingDelay: 0
      }
    ];
  } else
    return [
      {
        printInstantly: false,
        content: <span className="is-size-7-mobile">ls</span>,
        startDelay: 0,
        avgTypingDelay: 0
      },
      {
        printInstantly: true,
        content: links,
        startDelay: 0,
        avgTypingDelay: 0,
        stdTypingDelay: 0
      },
      {
        printInstantly: false,
        content: <span className="is-size-7-mobile"></span>,
        startDelay: 0,
        avgTypingDelay: 0
      }
    ];
}

interface ConsoleProps {
  isInitial?: boolean;
}

export const Console = ({ isInitial = true }: ConsoleProps) => {
  const [ipAddress, setIpAddress] = React.useState("localhost");
  const [joke, setJoke] = React.useState("");

  // Get IP Address of the visiting client
  React.useEffect(() => {
    async function fetchWelcomeMessage() {
      const res = await fetch("/.netlify/functions/get-welcome-message");
      try {
        const json = await res.json();
        if (json && json.ip) {
          setIpAddress(json.ip);
          setJoke(json.joke);
        }
      } catch (e) {
        console.log("Couldn't get the ip...");
      }
    }
    fetchWelcomeMessage();
  }, []);

  const steps = getMenuSteps(ipAddress, joke, isInitial);
  function handleNewValidationResult() {
    // setSteps([]);
  }

  return (
    <ConsoleInput
      key={1} // Need this key, as otherwise somehow the whole tree doesn't rerender??
      steps={steps}
      user={"dafrie"}
      host={ipAddress}
      onValidationResult={handleNewValidationResult}
    />
  );
};
