import React from "react";

interface IconProps {
  d: string;
  size: string | number;
  label?: string;
  style: object;
}

const Icon = ({ d, size = "1em", label, style: styles }: IconProps) => {
  return (
    <span className={"icon-root"} style={styles} role="figure">
      <svg
        version="1.1"
        width={size}
        height={size}
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={d} className={"icon"} />
      </svg>
      {label && <span className="icon-label">{label}</span>}
    </span>
  );
};

export default Icon;
