import { useState, useEffect } from "react";

export default function useKeyPress() {
  const [keysPressed, setKeyPressed] = useState(new Set([]));

  useEffect(() => {
    function downHandler({ key }) {
      // setKeyPressed(new Set(keysPressed).add(key));
      setKeyPressed(keysPressed.add(key));
    }

    const upHandler = ({ key }) => {
      // setKeyPressed(new Set(keysPressed).delete(key));
      setKeyPressed(keysPressed.delete(key));
    };
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keysPressed;
}
