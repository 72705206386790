import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type Keyword = "blog" | "personal site";

interface SEOProps {
  lang?: "en" | "de";
  meta?: any[];
  keywords?: Keyword[];
  description?: string;
  title?: string;
}

const SEO = ({
  description = "",
  lang = "en",
  meta = [],
  keywords = [],
  title = ""
}: SEOProps) => {
  const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  const {
    title: siteTitle,
    description: siteDescription,
    author
  } = data.site.siteMetadata;
  const metaTitle = title || siteTitle;
  const metaDescription = description || siteDescription;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      titleTemplate={title ? `${title} :: ${siteTitle}` : siteTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `twitter:creator`,
          content: author
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
    />
  );
};

export default SEO;
