import React, { ReactNode } from "react";
import { ThemeToggler as GThemeToggler } from "gatsby-plugin-dark-mode";

import "./all.sass";
import useSiteMetadata from "./useSiteMetadata";
import SEO from "./SEO";
import { ThemeProvider } from "../context/ThemeContext";
import { Console } from "./Console";
import ThemeToggler from "./ThemeToggler";

interface LayoutProps {
  children?: ReactNode;
  disableNavbar?: boolean;
  isInitial?: boolean;
}

const Layout = ({
  children,
  disableNavbar = false,
  isInitial = true
}: LayoutProps) => {
  const { title, description } = useSiteMetadata();
  return (
    <GThemeToggler>
      {({
        theme,
        toggleTheme
      }: {
        theme: string;
        toggleTheme: (theme: string) => void;
      }) => (
        <ThemeProvider value={{ theme, toggleTheme }}>
          <SEO />
          <>
            <section className="hero is-fullheight is-bold">
              {!isInitial ? (
                <>
                  <div style={{ flex: 1 }}>{children}</div>
                  <div className="pane-divider"></div>
                </>
              ) : null}
              <div
                className="container is-fluid"
                style={{ paddingTop: "32px" }}
              >
                <div className="is-pulled-right">
                  <ThemeToggler />
                </div>
              </div>
              <div
                className="fading-container hero-body"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end"
                }}
              >
                <div>
                  <Console isInitial={isInitial} />
                </div>
              </div>
            </section>
          </>

          {/*<Footer />*/}
        </ThemeProvider>
      )}
    </GThemeToggler>
  );
};

export default Layout;
